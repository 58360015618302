@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables.scss";

/*
 * Colors
 */
$black: #000;
$white: #fff;
$gray: #999;
$pink: #FF4081;

$color-facebook: #3b5998;
$color-twitter: #55acee;
$color-google-plus: #dd4b39;
$color-jeanee: #323232;
$color-other: $gray;
$color-action: #DDD;

$md-red-light: #F44336;
$md-red-normal: #D32F2F;
$md-red-dark: #B71C1C;

$md-green-light: #4CAF50;
$md-green-normal: #388E3C;
$md-green-dark: #1B5E20;

$md-blue-light: #2196F3;
$md-blue-normal: #1976D2;
$md-blue-dark: #0D47A1;

$color-brand-light: #33B5FB;
$color-brand-normal: $pink;
$color-brand-dark: #004F95;

$color-text-default: #231f20;
$color-border: $color-text-default;

/**
 *
 */
$font-size-default: 1.8rem;

/**
 *
 */
$transition-duration: .1s;

html {
  width: 100%;
  height: 100%;
  font-size: 50%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  @media (min-width: $screen-md-min) {
    font-size: 62.5%;
  }
}

body {
  background-color: transparent;
  height: inherit;
  font-size: $font-size-default;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

a {
  color: $color-text-default;
  &:hover,
  &:focus {
    color: $pink;
    text-decoration: none;
  }
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  line-height: 1.5;
}

h1 {
  margin-top: 0;
  line-height: 40px;
  &.brand-label {
    background: url("/img/icons/jeanee-logo-black-small.png") no-repeat left center;
    display: inline-block;
    padding-left: 62px;
  }
  span { font-weight: 400; }
}

h2 {
  margin-top: 30px;
}

p {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  &:last-child { margin-bottom: 0; }
}

b, strong {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

#start,
#impressum,
#disclaimer,
#datenschutz {
  background: url("/img/start/background-images/171825854-full-hd_mini.jpg") no-repeat center center fixed;
  position: relative;
  height: inherit;
  .container { color: $color-text-default; }
  &.overflow-auto { overflow: auto; }
}

#start {
  line-height: 1.6;
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    //text-transform: uppercase;
  }
  .h1, h1 {
    font-size: 3.6rem;
    margin-bottom: 1em;
  }
  .h2, h2 {
    font-size: 3.0rem;
    margin-bottom: 0.5em;
    &.large {
      font-size: 5.0rem;
      text-transform: uppercase;
    }
  }
  .h3, h3 {
    font-size: 2.2rem;
    margin-bottom: 2em;
  }
  ul {
    border-top: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
  }
  .form-control {
    border-style: none;
  }
}

.section-title {
  text-transform: uppercase;
  margin-bottom: 1em;
}

#impressum,
#disclaimer,
#datenschutz {
  main {
    margin-top: 10%;
    margin-bottom: 10%;
  }
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
  border: none;
  border-top: 1px solid rgba(0,0,0,0.54);
  color: rgba(0,0,0,0.54);
  text-align: center;
  &.login:after {
    content: "Anmelden";
    display: inline-block;
    position: relative;
    top: -0.7em;
    padding: 0 0.25em;
    background: $white;
  }
  &.register:after {
    content: "Registrieren";
    display: inline-block;
    position: relative;
    top: -0.7em;
    padding: 0 0.25em;
    background: $white;
  }
}

.brand-color {
  background-color: $color-brand-normal;
}

/**
 * Main
 */
main {
  //background-color: rgba(255,255,255,.35);
}

.logged-in {
  &.true {
    display: none;
  }
  &.false {
    display: block;
  }
}

#ms-create-wrap {
  width: 100%;
  > * {
    float: left;
  }
  #ms-create {
    background-color: #3F51B5;
    color: #fff;
    width: 100%;
    .fa { margin-right: 1rem; }
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.btn-box {
  background: rgba(0,0,0,0.25);
  padding: 10px;
}

/**
 * Background video
 */
.video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background-size: 100% 100%;
  background-color: black;
  background-image: url("/img/start/poster/poster.jpg");
  background-position: center center;
  background-size: cover;
  object-fit: cover;
  z-index: -1;
}

/**
 * Forms
 */
.form-group {
  position: relative;
  &.has-feedback {
    border-radius: 2px;
    //padding: 10px;
  }
}
.form-group-lg .form-control+.form-control-feedback, .input-group-lg+.form-control-feedback, .input-lg+.form-control-feedback {
  width: 4.583333333rem;
  height: 4.583333333rem;
  line-height: 4.583333333rem;
}
.form-control {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  background-color: rgba(255,255,255,.7);
  &:focus {
    background-color: rgba(255,255,255,.75);
    border-color: $md-blue-light;
  }
  &-feedback {
    top: 10px;
    right: 10px;
  }
}
.input-lg {
  line-height: 2.4rem;
  height: initial;
  padding: 2.3rem 3.6rem;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  font-size: 2.0rem;
}

.has-success {
  .form-control {
    border-color: $md-green-light;
    &:focus { border-color: $md-green-light; }
  }
  .form-control-feedback { color: $md-green-dark; }
}

.has-error {
  .form-control {
    border-color: $md-red-normal;
    &:focus { border-color: $md-red-normal; }
  }
  .form-control-feedback { color: $md-red-dark; }
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: rgba(255,255,255,.75);
  opacity:0.5;
}

/**
 * Navbar
 */
$white: #fff;
$navbar-default-bg: #323232;
$navbar-default-brand-color: $white;
$navbar-default-link-color: $white;
$navbar-default-link-disabled-color: darken($navbar-default-link-color, 50);
$navbar-default-toggle-icon-bar-bg: darken($navbar-default-link-color, 50);
$navbar-default-link-active-color: $white;

// Default navbar
.navbar-default {
  background-color: $navbar-default-bg;
  border-style: none;

  .navbar-brand {
    color: $navbar-default-brand-color;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 26px;
    font-weight: 300;
    line-height: 40px;
    padding: 5px 0;
    &:hover,
    &:focus {
      color: darken($navbar-default-brand-color, 10);
    }
    b,
    strong {
      font-family: 'Roboto Condensed', sans-serif;
    }
  }

  .navbar-nav {
    > li > a {
      color: $navbar-default-link-color;

      &:hover,
      &:focus {
        color: darken($navbar-default-link-color, 10);
      }
    }
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: $navbar-default-link-color;
      }
    }
    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: $navbar-default-link-disabled-color;
      }
    }
  }

  .navbar-toggle {
    border-color: transparent;
    &:hover,
    &:focus {
      background-color: transparent;
      .icon-bar {
        background-color: $white;
      }
    }
    .icon-bar {
      background-color: $navbar-default-toggle-icon-bar-bg;
    }
  }

  .navbar-collapse,
  .navbar-form {
    border-style: none;
  }

  // Dropdown menu items
  .navbar-nav {
    // Remove background color from open dropdown
    > .open > a {
      &,
      &:hover,
      &:focus {
        color: $navbar-default-link-active-color;
      }
    }
  }


  // Links in navbars
  //
  // Add a class to ensure links outside the navbar nav are colored correctly.

  .navbar-link {
    color: $navbar-default-link-color;
    &:hover {
      color: $navbar-default-link-color;
    }
  }

  .btn-link {
    color: $navbar-default-link-color;
    &:hover,
    &:focus {
      color: $navbar-default-link-color;
    }
    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus {
        color: $navbar-default-link-color;
      }
    }
  }
}


.navbar-default .navbar-collapse,
.navbar-default .navbar-form { border-style: none; }

.navbar-transparent {
  background: linear-gradient(rgba(30,30,30,0.7), transparent);
  border-style: none;
}

.navbar-transparent .navbar-brand {
  color: rgba(255,255,255,0.9);
}

.navbar-transparent .navbar-brand:focus,
.navbar-transparent .navbar-brand:hover {
  color: #fff;
}

.navbar-transparent .navbar-nav > li > a {
  color: rgba(255,255,255,0.9);
}

.navbar-transparent .navbar-nav > li > a:focus,
.navbar-transparent .navbar-nav > li > a:hover {
  color: #fff;
}

.navbar-transparent .navbar-nav>.open>a,
.navbar-transparent .navbar-nav>.open>a:focus,
.navbar-transparent .navbar-nav>.open>a:hover {
  background-color: rgba(0,0,0,0.15);
  color: #fff;
}

.navbar-transparent {
  .dropdown-menu {
    background-color: rgba(0,0,0,0.15);
    border-style: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    > li > a {
      color: rgba(255,255,255,0.9);
      &:focus,
      &:hover {
        color: #fff;
        background-color: transparent;
      }
    }
  }
}

.dropdown-menu {
  &.text-right {
    text-align: right;
    > li > a {
      padding-right: 15px;
    }
  }
}

.navbar-nav .fa:before,
.btn-icon .fa:before {
  padding-right: 10px;
}

.navbar-inverse {
  background-color: #0082C8;
  border-color: #C7E3F7;
  color: #ffffff;
}

.navbar-inverse .navbar-brand {
  color: #0082C8;
  background: #ffffff;
}

/**
 * Kontakt
 */
#kontakt {
  width: 100%;
  height: 50px;
  ul {
    padding-top: 1.2rem;
    padding-bottom: $font-size-default;
    margin-bottom: 0;
  }
  li {
    line-height: 3.6rem;
  }
  a {
    display: inline-block;
    color: $color-text-default;
    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
      color: $pink;
    }
  }
  .fa {
    margin-right: 10px;
  }
  .section-title {
    font-size: 3.8rem;
    font-weight: 400;
  }
}

@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins.scss";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons.scss";

.navbar {
  .brand-logo {
    float: left;
    margin: 0;
    padding: 12px;
  }
  #login-text {
    margin-right: 10px;
  }
  .btn {
    display: inline-block;
    font-size: 1.6rem;
    font-weight: inherit;
    line-height: 1.5;
    color: #FFFFFF;
    margin-top: 7px;
    border-style: none;
    padding: 0.6rem 1.2rem;
    &:active,
    &.active {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &:hover,
    &:focus,
    &.focus {
      color: #FFFFFF;
    }
    &.brand-color {
      &:hover,
      &:focus,
      &.focus {
        @include button-variant(#FFFFFF, lighten($color-brand-normal, 20%), $color-brand-normal);
      }
    }
    &.facebook {
      background-color: $color-facebook;
      &:hover,
      &:focus,
      &.focus {
        @include button-variant(#FFFFFF, lighten($color-facebook, 20%), $color-facebook);
      }
    }
  }
  .navbar-right {
    .btn {
      margin-left: 7px;
    }
  }
  @media (min-width: $screen-sm-min) {
    >.container .navbar-brand,
    .navbar>.container-fluid .navbar-brand {
      margin-left: 0;
    }
  }
}

.btn-primary {
  @include button-variant(#fff, $color-brand-normal, $color-brand-normal);
}

.btn {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  color: $white;
  transition: all $transition-duration;
  border-style: none;
  border-radius: 4px;
  &.active,
  &:active {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &.focus, &:focus, &:hover{
    border-style: none;
    color: $white;
  }
  &:hover {
    color: $white;
  }
  &.btn-facebook {
    background-color: $color-facebook;
    &:active,
    &:hover {
      background-color: darken($color-facebook, 5%);
    }
  }
  &.btn-twitter {
    background-color: $color-twitter;
    &:active,
    &:hover {
      background-color: darken($color-twitter, 5%);
    }
  }
  &.btn-google-plus {
    background-color: $color-google-plus;
    &:active,
    &:hover {
      background-color: darken($color-google-plus, 5%);
    }
  }
  &.btn-jeanee {
    background-color: $color-jeanee;
    &:active,
    &:hover {
      background-color: lighten($color-jeanee, 5%);
    }
    &:before {
      content: "";
      display: inline-block;
      width: 25px;
      height: 18px;
      margin-right: 0.5em;
      background: url("/img/icons/jeanee-logo-xsmall.png") no-repeat left center;
    }
  }
  &.btn-other {
    background-color: $color-other;
    &:active,
    &:hover {
      background-color: darken($color-other, 5%);
    }
  }
  &.btn-action {
    background-color: $color-action;
    color: $color-text-default;
    &:active,
    &:hover {
      background-color: darken($color-action, 5%);
    }
  }
  &.btn-md {
    font-size: 1.8rem;
    line-height: 2.6rem;
    padding: 1.1rem 3.3rem;
    text-transform: none;
  }
  &.btn-lg {
    font-size: $font-size-default;
    line-height: 2.4rem;
    padding: 2.3rem 3.6rem;
    text-transform: uppercase;
  }
}

.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
  outline: none;
}

button[disabled],
.btn[disabled] {
  &:before {
    content: "";
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    left: 2.3rem;
    border: 2px solid;
    border-radius: 50%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
  }
}

.check:before {
  content: "✓";
  padding-right: 10px;
}

video, embed, object {
  max-width: 100%;
  height: auto;
}

.signal {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 10px;
  left: 50%;
  margin-left: -15px;
  border: 2px solid;
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

#hello {
  min-height: 45px;
  height: 45px;
  max-height: 45px;
  margin-bottom: 30px;
  .content { margin-bottom: 0; }
  .signal {
    border-color: $white;
  }
}

#check-slug {
  font-weight: 400;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

#feedback {
  font-weight: 400;
  padding: 10px 0;
  &.info {
    color: $md-blue-normal;
  }
  &.success {
    color: $md-green-normal;
  }
  &.error {
    color: $md-red-normal;
  }
  b,
  strong {
    font-weight: 700;
  }
}
.has-success .form-control-feedback {
  display: none;
}
.has-error .form-control-feedback {
  display: none;
}

.section {
  position: relative;
  background-size: cover;
}

ol {
  counter-reset: my-counter;
  li {
    position: relative;
    border-bottom: 1px solid $color-border;
    &:first-child {
      border-top: 1px solid $color-border;
    }
    padding: 0.6rem 0 0.6rem 5rem;
    min-height: 6.9rem;
    &:before {
      content: counter(my-counter);
      counter-increment: my-counter;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -1.5rem;
      line-height: 1.6;
      background-color: #3F51B5;
      color: $white;
      border-radius: 50%;
      width: 3rem;
      height: 3rem;
      text-align: center;
    }
  }
}

.flex {
  display: flex;
  align-items: center;
  &[layout="row"] {
    -webkit-flex-flow: row wrap;
    justify-content: space-between;
  }
}

.arrow-link {
  width: 64px;
  height: 64px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -32px;
  display: block;
  opacity: 0.4;
  transition: opacity 0.2s;
  &.up { background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNjRweCIgdmVyc2lvbj0iMS4xIiBoZWlnaHQ9IjY0cHgiIHZpZXdCb3g9IjAgMCA2NCA2NCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNjQgNjQiPgogIDxnPgogICAgPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTMuMzUyLDQ4LjI5NmwyOC41Ni0yOC4zMjhsMjguNTgsMjguMzQ3YzAuMzk3LDAuMzk0LDAuOTE3LDAuNTksMS40MzYsMC41OWMwLjUyLDAsMS4wNC0wLjE5NiwxLjQzNi0wLjU5ICAgYzAuNzkzLTAuNzg3LDAuNzkzLTIuMDYyLDAtMi44NDlsLTI5Ljk4LTI5LjczNWMtMC4yLTAuMi0wLjQ5NC0wLjM3NS0wLjc1Ny0wLjQ3NWMtMC43NS0wLjI4Mi0xLjU5Ny0wLjEwNy0yLjE2NiwwLjQ1NiAgIEwwLjQ3OSw0NS40NDdjLTAuNzkzLDAuNzg3LTAuNzkzLDIuMDYyLDAsMi44NDlDMS4yNzMsNDkuMDgyLDIuNTU4LDQ5LjA4MiwzLjM1Miw0OC4yOTZ6Ii8+CiAgPC9nPgo8L3N2Zz4K); }
  &.down { background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNjRweCIgdmVyc2lvbj0iMS4xIiBoZWlnaHQ9IjY0cHgiIHZpZXdCb3g9IjAgMCA2NCA2NCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNjQgNjQiPgogIDxnPgogICAgPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0ibTYwLjUzMywxNS43MzNsLTI4LjU2LDI4LjMyOC0yOC41NzktMjguMzQ4Yy0wLjM5Ny0wLjM5NC0wLjkxNy0wLjU5LTEuNDM3LTAuNTlzLTEuMDM5LDAuMTk2LTEuNDM2LDAuNTljLTAuNzkzLDAuNzg3LTAuNzkzLDIuMDYyIDAsMi44NDlsMjkuOTgsMjkuNzM1YzAuMiwwLjIgMC40OTQsMC4zNzUgMC43NTcsMC40NzYgMC43NSwwLjI4MiAxLjU5NywwLjEwNyAyLjE2Ni0wLjQ1NmwyOS45ODEtMjkuNzM1YzAuNzkzLTAuNzg3IDAuNzkzLTIuMDYyIDAtMi44NDktMC43OTQtMC43ODYtMi4wNzgtMC43ODYtMi44NzIsNy4xMDU0M2UtMTV6Ii8+CiAgPC9nPgo8L3N2Zz4K); }
  &:focus,
  &:hover { opacity: 0.7; }
}

#status {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #3b5998;
  color: #fff;
  padding: 5px 10px;
}

.frosted-glass {
  background-color: rgba(255,255,255,0.8);
  padding-top: 6.6rem;
  padding-bottom: 6.6rem;
}

#backdrop {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1040;
  background-color: rgba(0,0,0,0.48);
  cursor: pointer;
}

/**
 * Miscellaneous
 */
::-moz-selection {
  background: #0082C8;
  color: #FFFFFF;
}
::selection {
  background: #0082C8;
  color: #FFFFFF;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/**
 * Breakpoints
 */
@media (max-width: $screen-xs-max) {
  .ms-create-col {
    &:first-child { padding-right: 0; }
    &:last-child { padding-left: 0; }
    &:last-child > div { padding-left: 0; }
  }
  ol {
    [class^="col-"]:first-child {
      > li:last-child {
        border-bottom: 0;
      }
    }
  }
  #start ul.address {
    border-bottom: 0;
  }
  #meta-links {
    > li:last-child {
      display: none;
    }
  }
}
@media (min-width: $screen-xs-min) {
  .ms-create-col {
    &:first-child { padding-right: 0; }
    &:last-child { padding-left: 0; }
    &:last-child > div { padding-left: 0; }
  }
}
@media (min-width: $screen-md-min) {
  .btn-box {
    display: inline-block;
    margin: 30px auto 0;
  }
  #formErstellen .form-group {
    margin-top: 30px;
  }
  .ms-create-col {
  }
}

/* Removing The Dotted Outline */
a {
  &:focus {
    outline: 0;
  }
  outline: 0;
}
object, embed {
  outline: 0;
}
input::-moz-focus-inner {
  border: 0;
}

.md-button {
  border-radius: 3px;
  box-sizing: border-box;
  color: currentColor;

  user-select: none;
  position: relative; //for child absolute-positioned <canvas>

  outline: none;
  border: 0;
  display: inline-block;
  align-items: center;
  padding: 0 6px;
  margin: 7px 8px;
  //line-height: 36px;
  //min-height: 36px;
  background: transparent;
  white-space: nowrap;
  // override for FAB, icon buttons, etc.
  min-width: 88px;

  text-align: center;

  // Always uppercase buttons
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;

  cursor: pointer;
  overflow: hidden;   // for ink containment

  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:focus {
    outline: none;
  }

  &:hover, &:focus {
    text-decoration: none;
  }

  &.ng-hide, &.ng-leave {
    transition: none;
  }

  &.md-cornered {
    border-radius: 0;
  }

  &.md-icon {
    padding: 0;
    background: none;
  }

  &.md-raised {
    &:not([disabled]) {
      //@include md-shadow-bottom-z-1();
    }
  }
}

.response {
  text-align: left;
  margin: 0.5em 0;
  &.error {
    color: $md-red-normal;
  }
}

.login-modal-container {

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .login-modal {
    @media (max-width: 320px) {
      width: 320px;
    }
    @media (max-width: $screen-xs-max) {
      width: 100%;
      height: 100%;
    }
    @media (min-width: $screen-sm-min) {
      width: 350px;
    }
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: $white;
    position: relative;
    z-index: 1050;

    .md-button {
      min-width: 48px;
    }

    .modal-head {
      border-radius: 4px 4px 0 0;
      margin-bottom: 0;
    }

    .forgot-password {
      margin: 0 0 0.8em 0;
      a {
        color: #222;
      }
    }

    .separator {
      margin: 1.2em 0;
    }

    .agb_and_stuff {
      font-size: 0.8em;
      a {
        color: #aaa;
      }
    }
    .signal {
      border-color: $white;
      width: 24px;
      height: 24px;
      top: 12px;
      left: 12px;
      margin-left: 0;
    }
  }

  .h1, h1, .h2, h2 {
    padding: 0;
    margin: 0;
    cursor: default;
  }

  .h2, h2 {
    font-size: 26px;
  }

  .h3, h3 {
    margin-top: 30px;
  }

  header {
    background-color: $navbar-default-bg;
    color: $white;
    padding-left: 12px;
    img {
      padding: 12px;
    }
    h2 {
      background: url("/img/icons/jeanee-logo-small.png") no-repeat left 6px;
      padding-left: 48px;
    }
    strong {
      font-family: 'Roboto Condensed', sans-serif;
    }
    .fa {
      font-size: 28px;
      line-height: 36px;
    }
  }
  .content {
    min-height: 200px;
    padding: 0 2em 1em 2em;

    input, .btn {
      margin-bottom: 0.5em;
      width: 100%;
    }
    .form-control {
      font-size: 1.8rem;
      line-height: 2.6rem;
      height: 4.8rem;
      padding: 1.1rem 2.2rem;
    }
    .fa {
      margin-right: 0.5em;
    }
  }
  #form-facebook {
    position: relative;
  }
}
